import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from 'src/hooks/use-responsive';
import Iconify from 'src/components/iconify';
import { NAV, HEADER } from './config-layout';
import AccountPopover from './common/account-popover';
// import inactivityTime from '../../utils/checkActivity'
import { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { authLogout } from 'src/api';
import { useRouter } from 'src/routes/hooks';
import swal from 'sweetalert';


// import LanguagePopover from './common/language-popover';
// import NotificationsPopover from './common/notifications-popover';

// ----------------------------------------------------------------------
let countDownDate = new Date().getTime() + 180000;
export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const [showProgress, setShowProgress] = useState(false)
  const router = useRouter()
  // Test timer
  // const [seconds, setseconds] = useState(0)
  // const [minutes, setminutes] = useState(0)

  // inactivityTime();
  // Test Timer function
  // Update the count down every 1 second
  // let x = setInterval(function () {

  //   // Get today's date and time
  //   let now = new Date().getTime();

  //   // Find the distance between now and the count down date
  //   let distance = countDownDate - now;

  //   // Time calculations for days, hours, minutes and seconds
  //   setminutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
  //   setseconds(Math.floor((distance % (1000 * 60)) / 1000))
  //   // seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //   // If the count down is finished, write some text
  //   if (distance < 0) {
  //     clearInterval(x);
  //     setminutes(0)
  //     setseconds(0)
  //   }
  // }, 1000);

  const handleLogout = async () => {
    swal({
      text: 'هل تريد تسجيل الخروج',
      buttons: ["لا", "نعم"],
      dangerMode: true,
      closeOnClickOutside: false,
    }).then(async (ok) => {
      if (ok) {
        setShowProgress(true)
        const logedOUt = await authLogout()
        if (logedOUt.data.message) {
          secureLocalStorage.clear()
          sessionStorage.clear();
          setShowProgress(false)
          router.push('/login');
        }
      }
    })
  }

  const lgUp = useResponsive('up', 'lg');

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" alignItems="center" spacing={1}>
        {/* <LanguagePopover /> */}
        {/* <NotificationsPopover /> */}
        <AccountPopover logOut={handleLogout} />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        backgroundColor: 'transparent',
        // ...bgBlur({
        //   color: theme.palette.background.default,
        // }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.WIDTH}px)`,
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      {showProgress
        ?
        <Box sx={{ width: '95%', m: 'auto', pt: 2 }}>
          <LinearProgress color="success" />
        </Box>
        : ''
      }
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
