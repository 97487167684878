import { useLocation } from 'react-router-dom';
import { lazy } from 'react';
import secureLocalStorage from 'react-secure-storage';
const IndexPage = lazy(() => import('src/pages/app'));


export default function Protected({ childern }) {
    const userAuth = secureLocalStorage.getItem('object')
    const location = useLocation()
    const reg = /\/([a-fA-F0-9]{24})$/
    const url = (location.pathname).split(reg)[0]
    if (userAuth.user.permissions.furls.some((e) => e.path === url)) {
        return childern
    } else {
        return <IndexPage />
    }

}