import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
import { useResponsive } from 'src/hooks/use-responsive';
import Scrollbar from 'src/components/scrollbar';
import { NAV } from './config-layout';
// import navConfig from './config-navigation';
import secureLocalStorage from 'react-secure-storage';




// ----------------------------------------------------------------------

export default function Nav({ openNav, sx, onCloseNav }) {
  const userData = secureLocalStorage.getItem('object')
  const navConfig = userData.user.permissions.menus

  const pathname = usePathname();

  const upLg = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderAccount = (
    <Stack
      direction="column"
      sx={{
        mt: 12,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar sx={{ width: 70, height: 70 }} src='' alt="photoURL" />
      <Box>
        <Typography variant="h5" mt={1} align='center'>{`${userData.user.first_name} ${userData.user.last_name}`}</Typography>
        <Typography variant="body1" mt={1} align='center'>{`${userData.user.job}`}</Typography>

      </Box>
    </Stack>
  );

  const renderMenu = (
    <Stack component="nav" spacing={1} sx={{ px: 2, mt: 3 }}>
      {navConfig.map((item) => (
        <NavItem key={item.name} item={item} />
      ))}
    </Stack>
  );

  // const renderUpgrade = (
  //   <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
  //     <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
  //       <Box
  //         component="img"
  //         src="/assets/images/logo.png"
  //         sx={{ width: 200, position: 'absolute', top: -50 }}
  //       />

  //     </Stack>
  //   </Box>
  // );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        },
      }}
    >

      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />

      {/* {renderUpgrade} */}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
              ...sx,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();

  const active = item.path === pathname;
  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'h6',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Stack direction={'row'} spacing={2}>
        <img src={item.icon} alt={item.name} width={30} />
        <Typography variant='body'>{item.name}</Typography>
      </Stack>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
