import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import DashboardLayout from 'src/layouts/dashboard';
import Protected from 'src/utils/checkpermission';


export const IndexPage = lazy(() => import('src/pages/app'));
export const EmploeesPage = lazy(() => import('src/pages/users/main'));
export const EmploeesDataPage = lazy(() => import('src/pages/users/user'));
export const AddUserPage = lazy(() => import('src/pages/users/adduser'));

export const Mainstudents = lazy(() => import('src/pages/students/main'))
export const AddStudents = lazy(() => import('src/pages/students/addStudent'))
export const ReportStudents = lazy(() => import('src/pages/evaluation/reports-page'))
export const Students = lazy(() => import('src/pages/students/students'))

// Speacial Edu
export const StudentSE = lazy(() => import('src/pages/se/studentSe'))
export const ObjectLibrarySE = lazy(() => import('src/pages/se/categorySe'))
export const PEvalStudents = lazy(() => import('src/pages/se/preEvaluation'))
export const AddPEvalStudents = lazy(() => import('src/pages/se/addPreEval'))
export const StudentPlan = lazy(() => import('src/pages/se/plan'))
export const AddItemToPlan = lazy(() => import('src/pages/se/AddItemToPlan'))
export const AddResponseToPlan = lazy(() => import('src/pages/se/AddResponseToPlan'))
export const SeDepart = lazy(() => import('src/pages/departments/seDepart'))

// Housing
export const HousingDepart = lazy(() => import('src/pages/departments/housingDepart'))
export const HousingVillas = lazy(() => import('src/pages/housing/villas'))
export const StudentHouse = lazy(() => import('src/pages/housing/studenthouse'))
export const StudentEnterHouse = lazy(() => import('src/pages/housing/studententerhouse'))
export const StudentTransHouseAdd = lazy(() => import('src/pages/housing/studenttransaddhouse'))








export const Permission = lazy(() => import('src/pages/permissions/permissions'))
export const MainDeparts = lazy(() => import('src/pages/departments/main'))

// SpeachDepart
export const SpeachDepart = lazy(() => import('src/pages/departments/speachDepart'))
export const StudentSpeach = lazy(() => import('src/pages/speach/studentSpeach'))
export const PEvalStudentsSpeach = lazy(() => import('src/pages/speach/preEvaluation'))
export const StudentPlanSpeach = lazy(() => import('src/pages/speach/plan'))
export const AddItemToPlanSpeach = lazy(() => import('src/pages/speach/AddItemToPlan'))
export const AddResponseToPlanSpeach = lazy(() => import('src/pages/speach/AddResponseToPlan'))


export const LoginPage = lazy(() => import('src/pages/login'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const userAuth = secureLocalStorage.getItem('object')
  let authRouter = []
  if (userAuth) {
    if (userAuth.token) {
      authRouter = [
        {
          element: (
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          ),
          children:
            [
              { path: '/', element: <IndexPage /> },
              { path: 'employees', element: <Protected childern={<EmploeesDataPage />} /> },
              { path: 'employees/add', element: <Protected childern={<AddUserPage />} /> },
              { path: 'employeedit/:user_id', element: <Protected childern={<AddUserPage />} /> },
              { path: 'permissions', element: <Protected childern={<Permission />} /> },
              { path: 'students/add', element: <Protected childern={<AddStudents />} /> },
              { path: 'students', element: <Protected childern={<Students />} /> },
              { path: 'students/edit/:student_id', element: <Protected childern={<AddStudents />} /> },
              { path: 'students/reports/:student_id', element: <Protected childern={<ReportStudents />} /> },
              { path: 'departs', element: <Protected childern={<MainDeparts />} /> },
              { path: 'departs/se', element: <Protected childern={<SeDepart />} /> },
              { path: 'se/students', element: <Protected childern={<StudentSE />} /> },
              { path: 'se/objectlibrary', element: <Protected childern={<ObjectLibrarySE />} /> },
              { path: 'se/pevaluation', element: <Protected childern={<PEvalStudents />} /> },
              { path: 'se/addpevaluation/:student_id', element: <Protected childern={<AddPEvalStudents />} /> },
              { path: 'se/studentsplan/:student_id', element: <Protected childern={<StudentPlan />} /> },
              { path: 'se/additemtoplan', element: <Protected childern={<AddItemToPlan />} /> },
              { path: 'se/addresponsetoplan/:plan_id', element: <Protected childern={<AddResponseToPlan />} /> },
              { path: 'departs/speach', element: <Protected childern={<SpeachDepart />} /> },
              { path: 'speach/students', element: <Protected childern={<StudentSpeach />} /> },
              { path: 'speach/pevaluation/:student_id', element: <Protected childern={<PEvalStudentsSpeach />} /> },
              { path: 'speach/studentsplan/:student_id', element: <Protected childern={<StudentPlanSpeach />} /> },
              { path: 'speach/additemtoplan/:plan_id', element: <Protected childern={<AddItemToPlanSpeach />} /> },
              { path: 'speach/addresponsetoplan/:plan_id', element: <Protected childern={<AddResponseToPlanSpeach />} /> },
              { path: 'departs/housing', element: <Protected childern={<HousingDepart />} /> },
              { path: 'housing/villas', element: <Protected childern={<HousingVillas />} /> },
              { path: 'housing/students/enthouse', element: <Protected childern={<StudentHouse />} /> },
              { path: 'housing/students/enthouse/add', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/enthouse/edit/:std_id', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/enthouse/reject/:std_id', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/enthouse/approve/:std_id', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/transhouse/add/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },
              { path: 'housing/students/transhouse/edit/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },
              { path: 'housing/students/transhouse/reject/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },
              { path: 'housing/students/transhouse/approve/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },

            ]
          ,
        },
        {
          path: '/',
          children: [
            { path: '/', element: <Navigate to="/" /> },
            { path: 'login', element: <Navigate to="/" /> },
            { path: '404', element: <Page404 /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
      ]
    }
  } else {
    authRouter = [
      { path: 'login', element: <LoginPage /> },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/login" replace /> },
      // {
      //   path: '/',
      //   children: [
      //     { path: '/', element: <Navigate to="/login" /> },
      //     // { path: '*', element: <Navigate to="/login" /> },
      //   ],
      // },
    ]
  }
  const routes = useRoutes(authRouter)
  return routes
}
